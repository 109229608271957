
.show-conversations-button {
  display: none;
  @media (max-width: 1024px) {
    display: inline-block;
  }
}
.conversations {
  @media (max-width: 1024px) {
    &:not(.active) {
      display: none;
    }
  }
}
